import set from 'lodash/set';
import { Moment } from 'moment';

import { Caveat } from '@cimpress-technology/caveats';

import { IDPFulfillmentCapability } from '../components/inputPanels/state';
import { ProductConfiguration } from '../hooks/useProductConfiguration';
import { Link } from '../types';
import { ConfigurationCreation, VariableAttribute, createConfigurationUrl } from './configuration';
import fetchWithAuth from './fetchWithAuth';
import { getMerchantId } from './routingIngest';

export type TracingRecord = Omit<TracingRecordResponse, 'product' | 'destination'> & {
  product: {
    productVersion?: string;
    _links: {
      productConfiguration: Link;
    };
  };
  destination: {
    country: string;
    isPOBox: boolean;
    postalCode: string;
    addressType: AddressType;
    _embedded?: {
      deliveryConstraint?: {
        carrierServices?: string[];
        carrierServiceCapabilities?: string[];
      };
    };
    _links?: {
      pickupPoint: Link;
    };
  };
  ecommerceDeliveryOption?: {
    _links: {
      self: { href: string };
    };
  };
  fulfillmentCapabilities: IDPFulfillmentCapability[];
};

type TracingRecordResponse = {
  id: string;
  buyer: {
    _embedded: {
      merchant?: {
        merchantId: string;
      };
      account?: {
        accountId: string;
      };
    };
  };
  product: {
    _links?: {
      productConfiguration?: Link;
    };
    _embedded?: {
      productConfiguration: {
        mcpSku: string;
        variables: VariableAttribute[];
      };
    };
  };
  _embedded: {
    caveats: Caveat[];
  };
  destination: {
    country?: string;
    isPOBox: boolean;
    postalCode?: string;
    addressType?: AddressType;
    _embedded?: {
      deliveryConstraint?: {
        carrierServices?: string[];
        carrierServiceCapabilities?: string[];
      };
    };
    _links?: {
      pickupPoint: Link;
    };
  };
  createdAt: string;
  requestDateTime?: string;
  minutesToOrderSubmission?: number;
  deliverableQuantity: number;
  ignoreInTransitInventory?: boolean;
  _links: {
    ui: Link;
  };
};

export enum AddressType {
  residential = 'residential',
  commercial = 'commercial',
  pickupPoint = 'pickupPoint',
}

export type DebugBranchRequest = {
  productConfigurationUrl: string;
  merchantId: string;
  quantity: number;
  country?: string;
  postalCode?: string;
  pickupPointUrl?: string;
  isPOBox?: boolean;
  addressType?: AddressType;
  minutesToOrderSubmittal?: number;
  requestDateTime?: Moment;
  fulfillmentCapabilities?: string[];
  carrierServices?: string[];
  carrierServiceCapabilities?: string[];
  currencyCode?: string;
  ignoreInTransitInventory?: boolean;
};

export type Price = {
  product?: {
    price?: number;
    currency?: string;
  };
  shipping?: {
    price?: number;
    currency?: string;
  };
};

export type DeliverableDate = {
  date: Moment;
  expiryDate: string;
  price?: Price;
};

export type BranchLinks = {
  cutoffTimes?: Link;
  fulfiller?: Link;
  fulfillmentConfiguration?: Link;
  fulfillmentLocation?: Link;
  inventory?: Link;
  packEvaluation?: Link;
  productConfiguration: Link;
  productionDelay?: Link;
  productionTimeEvaluation?: Link;
  shipDate?: Link;
};

export type DebugBranch = {
  caveats: Caveat[];
  deliverableDates: DeliverableDate[];
  _links: BranchLinks;
};

export type DebugBranchResponse = {
  _embedded: {
    item: DebugBranch[];
  };
};

export const getTracingRecordByUrl = async (recordUrl: string): Promise<TracingRecord> => {
  const response = await fetchWithAuth<TracingRecordResponse>({
    endpointUrl: recordUrl,
  });

  return await formatTracingRecord(response);
};

export const getTracingRecord = async (recordId: string): Promise<TracingRecord> => {
  const response = await fetchWithAuth<TracingRecordResponse>({
    endpointUrl: process.env.REACT_APP_ITEM_DELIVERY_POSSIBILITIES_SERVICE_URL as string,
    route: `/v6/deliveryDateCollections/${recordId}`,
  });
  return await formatTracingRecord(response);
};

const formatTracingRecord = async (record: TracingRecordResponse): Promise<TracingRecord> => {
  if (!record.product._links?.productConfiguration?.href && record.product._embedded?.productConfiguration) {
    const quantityAttribute: VariableAttribute = {
      attributeKey: 'quantity',
      attributeValue: `${record.deliverableQuantity}`,
    };
    const configurationCreationBody: ConfigurationCreation = {
      mcpSku: record.product._embedded?.productConfiguration.mcpSku,
      variables: record.product._embedded?.productConfiguration.variables.concat([quantityAttribute]),
    };
    const configurationUrl = await createConfigurationUrl(configurationCreationBody);
    set(record, 'product._links.productConfiguration.href', configurationUrl);
  }

  const productConfiguration = await fetchWithAuth<ProductConfiguration>({
    endpointUrl: record.product._links?.productConfiguration?.href as string, //should be defined now
  });
  set(record, 'product.productVersion', productConfiguration.productVersion);

  if (!record.buyer._embedded.merchant?.merchantId) {
    const merchantId = await getMerchantId(record.buyer._embedded.account?.accountId);
    set(record, 'buyer._embedded.merchant.merchantId', merchantId);
  }

  return record as TracingRecord;
};
