import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import GenericSelector from '@cimpress-technology/generic-selector';

import auth from '../../auth';
import { useGetProductType } from '../../hooks/useProductOrchestration';
import { convertToMCPProduct } from '../../services/bes';
import { createConfigurationUrl } from '../../services/configuration';
import { ProductInputTextField } from './ProductInputTextField';
import messages from './messages';
import { ProductState as PS } from './state';

type AttributeSelectorRef = {
  getConfigurationUrl: () => Promise<string>;
};

type ProductInputPanelProps = {
  skuOrProductConfigurationText: string;
  setSkuOrProductConfigurationText: (v: string) => void;
  productVersion: string;
  setProductVersion: (v: string) => void;
  productState: PS;
  setProductState: (v: PS) => void;
  disabled: boolean;
};

type AttributeSelectorState = 'partiallyResolved' | 'fullyResolved';
type Attribute = {
  key: string;
  resolvedValue: string;
  isDisplayed: boolean;
};

const ProductInputPanel = ({
  skuOrProductConfigurationText,
  setSkuOrProductConfigurationText,
  productVersion,
  setProductVersion,
  productState,
  setProductState,
  disabled,
}: ProductInputPanelProps) => {
  const { formatMessage } = useIntl();
  const [showGenericSelector, setShowGenericSelector] = useState(true);

  const selector = useRef<AttributeSelectorRef>();
  const { data: productType } = useGetProductType({
    productIdOrConfigUrl: skuOrProductConfigurationText,
  });

  const onGenericSelectorChange = async ({
    state,
    attributes,
  }: {
    state: AttributeSelectorState;
    attributes: Attribute[];
  }) => {
    let productConfigurationUrl = '';
    if (state === 'fullyResolved') {
      // Product config URLs
      try {
        // Will throw if the value isn't a valid URL
        new URL(skuOrProductConfigurationText);
        productConfigurationUrl = skuOrProductConfigurationText;
      } catch {
        if (productType === 'Merchant') {
          // PRDs
          try {
            const selections = attributes.filter(a => a.isDisplayed);
            productConfigurationUrl = await convertToMCPProduct({
              productId: skuOrProductConfigurationText,
              version: productVersion,
              selections,
            });
          } catch {
            //ignore
          }
        } else {
          // Fulfiller products (CIMs)
          try {
            const selections = attributes
              .filter(a => a.isDisplayed)
              .map(a => ({ attributeKey: a.key, attributeValue: a.resolvedValue }));
            productConfigurationUrl = await createConfigurationUrl({
              mcpSku: skuOrProductConfigurationText,
              variables: selections,
              productVersion,
            });
          } catch {}
        }
      }
      setProductState({ ...productState, productConfigurationUrl });
    }
  };

  return (
    <div>
      <h3>{formatMessage(messages.enterAProductSkuOrProductConfigurationUrl)}</h3>
      <ProductInputTextField
        skuOrProductConfigurationText={skuOrProductConfigurationText}
        setSkuOrProductConfigurationText={setSkuOrProductConfigurationText}
        productVersion={productVersion}
        setProductVersion={setProductVersion}
        productState={productState}
        setProductState={setProductState}
        disabled={disabled}
        setShowGenericSelector={setShowGenericSelector}
      />
      {(productState.mcpSku || productState.productConfigurationUrl) && showGenericSelector ? (
        <GenericSelector
          productId={productState.mcpSku}
          productVersion={productState.productVersion}
          configurationUrl={productState.productConfigurationUrl}
          authToken={auth.getAccessToken()}
          onChange={onGenericSelectorChange}
          ref={(instance: AttributeSelectorRef) => {
            selector.current = instance;
          }}
          disabled={disabled}
        />
      ) : undefined}
    </div>
  );
};

export default ProductInputPanel;
