import { css } from '@emotion/css';
import moment from 'moment';
import React, { useReducer, useState } from 'react';

import { Card } from '@cimpress/react-components';

import auth from '../auth';
import { getIdsFromEdoUrl } from '../helpers/urlhelpers';
import { useCalculatorState } from '../hooks/useCalculatorState';
import useDebounce from '../hooks/useDebounce';
import { useIDPTracingRecord } from '../hooks/useIDPTracingRecord';
import { useItem } from '../hooks/useItem';
import { useResourceSearch } from '../hooks/useResourceSearch';
import { useAppSettings, useGlobalSettings } from '../hooks/useSettings';
import { defaultDeliveryCalculatorState } from '../reducers/defaults';
import tabActionReducer from '../reducers/tabActionReducer';
import { TracingRecord } from '../services/itemDeliveryPossibilities';
import { DeliveryConfiguration, Item, ItemWithDeliveryDetails } from '../services/items';
import DottedLine from './DottedLine';
import InputTabs from './InputTabs';
import ItemSelector from './ItemSelector';
import ResultCard from './ResultCard';
import SearchInput from './SearchInput';
import {
  AddressType,
  defaultDeliveryConstraintsState,
  defaultMerchantState,
  defaultProductState,
  DeliveryConstraintsState,
  IDPFulfillmentCapability,
  ItemFulfillmentCapability,
  LocationState,
  MerchantState,
} from './inputPanels/state';
import { SelectField } from './types';

const gridContainerCss = css`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
`;

const HomePage = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [loadedTracingRecordId, setLoadedTracingRecordId] = useState('');
  const [loadedItemId, setLoadedItemId] = useState('');

  const {
    state,
    updateState,
    updateLocationState,
    updateDeliveryConstraintsState,
    updateMerchantState,
    updateProductState,
  } = useCalculatorState();
  const { search, idpTracingRecordId, itemId, skuOrProductConfiguration, productVersion } = state;

  const debouncedState = useDebounce(state, 500);

  const { isLoading: isLoadingSettings, data: settingsData } = useAppSettings({});
  const { isLoading: isLoadingGlobalSettings, data: globalSettingsData } = useGlobalSettings({
    authToken: auth?.getAccessToken(),
  });

  const [deliveryCalculatorState, deliveryCalculatorDispatch] = useReducer(
    tabActionReducer,
    defaultDeliveryCalculatorState,
  );

  const populateFromIDPTracingRecord = (tracingRecord: TracingRecord) => {
    const edoIds = getIdsFromEdoUrl(tracingRecord.ecommerceDeliveryOption?._links.self.href);
    const { ecommerceDeliveryGroupId, ecommerceDeliveryOptionId } = edoIds || {};

    const merchantState: MerchantState = {
      merchantId: tracingRecord.buyer._embedded.merchant?.merchantId || defaultMerchantState.merchantId,
      requestDate: tracingRecord.requestDateTime
        ? moment(tracingRecord.requestDateTime)
        : moment(tracingRecord.createdAt),
      minutesToOrderSubmittal: tracingRecord.minutesToOrderSubmission ?? 0,
      ignoreInTransitInventory: tracingRecord.ignoreInTransitInventory ?? false,
      ecommerceDeliveryGroup: ecommerceDeliveryGroupId,
      ecommerceDeliveryOption: ecommerceDeliveryOptionId,
    };

    const productState = {
      mcpSku: defaultProductState.mcpSku,
      productVersion: tracingRecord.product.productVersion,
      productConfigurationUrl: tracingRecord.product._links.productConfiguration.href,
    };

    let locationState;
    const pickupPointUrl = tracingRecord.destination._links?.pickupPoint.href;

    if (pickupPointUrl) {
      locationState = populateLocation({ pickupPointUrl });
    } else {
      locationState = populateLocation({
        address: tracingRecord.destination,
        addressType: tracingRecord.destination.addressType,
      });
    }

    let deliveryConstraintsState: DeliveryConstraintsState = defaultDeliveryConstraintsState;
    if (tracingRecord.destination._embedded?.deliveryConstraint) {
      deliveryConstraintsState = populateDeliveryConstraints(
        tracingRecord.fulfillmentCapabilities, // The tracing record will already have the fulfillment capabilities mapped from IDP
        tracingRecord.destination._embedded.deliveryConstraint,
      );
    }

    setLoadedTracingRecordId(tracingRecord.id);
    updateState({
      ...merchantState,
      ...productState,
      ...locationState,
      ...deliveryConstraintsState,
      skuOrProductConfiguration: tracingRecord.product._links.productConfiguration.href,
      idpTracingRecordId: tracingRecord.id,
      search: '',
      clearSearchOnUpdate: true,
    });
  };

  const { isLoading: isLoadingIDPTracingRecord } = useIDPTracingRecord({
    idpTracingRecordId: idpTracingRecordId || '',
    enabled: idpTracingRecordId !== loadedTracingRecordId,
    onSuccess: tracingRecord => tracingRecord && populateFromIDPTracingRecord(tracingRecord),
  });

  const { isLoading: isLoadingItem } = useItem({
    itemId: itemId || '',
    timezone: globalSettingsData?.global?.timezone,
    enabled: itemId !== loadedItemId,
    onSuccess: item => {
      populateFromItem(item);
    },
  });

  const populateFromItem = (item: ItemWithDeliveryDetails | undefined) => {
    if (item) {
      const edoIds = getIdsFromEdoUrl(item._links.ecommerceDeliveryOption?.href);
      const { ecommerceDeliveryGroupId, ecommerceDeliveryOptionId } = edoIds || {};

      const merchantState: MerchantState = {
        merchantId: item.merchantId,
        requestDate: item.requestDate,
        minutesToOrderSubmittal: 0,
        ignoreInTransitInventory: false,
        ecommerceDeliveryGroup: ecommerceDeliveryGroupId,
        ecommerceDeliveryOption: ecommerceDeliveryOptionId,
      };

      const productState = {
        mcpSku: item.mcpSku,
        productVersion: item.productVersion,
        productConfigurationUrl: item.productConfigurationUrl,
      };

      let locationState;
      if (item.deliveryRequest) {
        const pickupPointUrl = item.deliveryRequest._links?.pickupPoint?.href;

        if (pickupPointUrl) {
          locationState = populateLocation({ pickupPointUrl });
        } else {
          locationState = populateLocation({
            address: item.deliveryRequest.destinationAddress,
            addressType:
              item.deliveryRequest.destinationAddress?.isResidential === false ? 'commercial' : 'residential',
          });
        }
      } else if (item.destinationAddress) {
        locationState = populateLocation({
          address: item.destinationAddress,
          addressType: item.destinationAddress?.isResidential ? 'residential' : 'commercial',
        });
      }
      const fulfillmentCapabilities = getFulfillmentCapabilitiesForItem(
        item.fulfillmentCapabilities,
        item.deliveryConfigurations,
      );
      const deliveryConstraintsState = populateDeliveryConstraints(fulfillmentCapabilities, item.deliveryConstraints);
      setLoadedItemId(item.itemId);
      updateState({
        itemId: item.itemId,
        search: '',
        ...productState,
        ...merchantState,
        ...locationState,
        ...deliveryConstraintsState,
        skuOrProductConfiguration: item.mcpSku,
        clearSearchOnUpdate: true,
      });
    }
  };

  const populateLocation = ({
    address,
    addressType,
    pickupPointUrl,
  }: {
    address?: {
      postalCode?: string;
      country?: string;
      isPOBox?: boolean;
    };
    addressType?: AddressType;
    pickupPointUrl?: string;
  }) => {
    const type: AddressType = addressType;
    const locationState: LocationState = {
      postalCode: address?.postalCode || '',
      deliveryCountry: address?.country || '',
      poBox: address?.isPOBox || false,
      addressType: type,
      pickupPointUrl: pickupPointUrl || '',
    };
    return locationState;
  };

  const getFulfillmentCapabilitiesForItem = (
    fulfillmentCapabilities: ItemFulfillmentCapability[],
    deliveryConfigurations: DeliveryConfiguration[],
  ): IDPFulfillmentCapability[] => {
    const supportedFulfillmentCapabilities = ['cashOnDelivery', 'whiteLabelling', 'moneyBackGuaranteedDelivery'];

    const capabilities = fulfillmentCapabilities.reduce((acc, capability) => {
      if (supportedFulfillmentCapabilities.includes(capability)) {
        acc.push(capability as IDPFulfillmentCapability);
      }

      return acc;
    }, [] as IDPFulfillmentCapability[]);

    // samplesplitshipping is not included in the fulfillment capabilities array. To get this value, we need to check for isSample to be true
    // in the delivery configurations array for at least one of the items
    const sampleSplitShipping = deliveryConfigurations?.some(config => config.isSample);

    if (sampleSplitShipping) {
      capabilities.push('sampleShipping');
    }

    return capabilities;
  };

  const populateDeliveryConstraints = (
    fulfillmentCapabilities: IDPFulfillmentCapability[],
    deliveryConstraints: {
      carrierServiceCapabilities?: string[];
      carrierServices?: string[];
    },
  ) => {
    const deliveryConstraintsState = {
      fulfillmentCapabilities,
      carrierServices: deliveryConstraints?.carrierServices ?? [],
      carrierServiceCapabilities: deliveryConstraints?.carrierServiceCapabilities ?? [],
    };
    return deliveryConstraintsState;
  };

  const { isLoading: isLoadingSearch, refetch: refetchSearch } = useResourceSearch({
    resourceId: search || '',
    timezone: globalSettingsData?.global?.timezone,
    onSuccess: data => {
      if (data?.idpTracingRecord) {
        populateFromIDPTracingRecord(data.idpTracingRecord);
      } else if (data?.items) {
        setItems(data?.items ?? []);
        const allItemIds = data?.items?.map(item => item.itemId) ?? [];
        const selectedItemId = allItemIds.includes(itemId || '') ? itemId : allItemIds[0] ?? '';
        updateState({ itemId: selectedItemId });
        if (data.items.length === 1) {
          populateFromItem(data.items[0]);
        }
      }
    },
  });

  const onChangeSelectedItem = (selection: SelectField) => {
    const item = items?.find(item => item.itemId === selection.value);
    updateState({ itemId: item?.itemId });
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ search: e.target.value });
  };

  const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateState({ search: search?.trim() });
    refetchSearch();
  };

  const isLoadingUserSettings = isLoadingGlobalSettings && isLoadingSettings;

  return (
    <div className="container-fluid">
      <div className={gridContainerCss}>
        <Card>
          <h4>Populate from an existing order, item, or delivery possibilities tracing record:</h4>
          <SearchInput
            value={search || ''}
            onChange={onSearchChange}
            onSubmit={onSubmitSearch}
            isLoading={isLoadingSearch}
          />
          {items.length > 1 ? <ItemSelector items={items} value={itemId} onChange={onChangeSelectedItem} /> : <></>}
          <DottedLine />
          <h4>Or input manually:</h4>
          <InputTabs
            skuOrProductConfigurationText={skuOrProductConfiguration || ''}
            setSkuOrProductConfigurationText={(value: string) => updateState({ skuOrProductConfiguration: value })}
            productVersion={productVersion || ''}
            setProductVersion={(value: string) => updateState({ productVersion: value })}
            state={state}
            setMerchantState={updateMerchantState}
            setProductState={updateProductState}
            setLocationState={updateLocationState}
            setDeliveryConstraintsState={updateDeliveryConstraintsState}
            isLoadingItem={isLoadingItem || isLoadingIDPTracingRecord}
            dispatch={deliveryCalculatorDispatch}
            deliveryCalculatorState={deliveryCalculatorState}
            timezone={globalSettingsData?.global?.timezone}
          />
        </Card>
        <ResultCard
          isLoadingSettings={isLoadingUserSettings}
          state={debouncedState}
          timezone={globalSettingsData?.global?.timezone}
          currencyCode={settingsData?.currencyCode?.value}
        />
      </div>
    </div>
  );
};

export default HomePage;
