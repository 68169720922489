import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { colors, Button, TextField } from '@cimpress/react-components';

import { isCustomQuotesProduct } from '../../helpers/customquotes';
import { useGetProductType } from '../../hooks/useProductOrchestration';
import messages from './messages';
import { ProductState } from './state';

type ProductInputTextFieldProps = {
  skuOrProductConfigurationText: string;
  setSkuOrProductConfigurationText: (v: string) => void;
  productVersion: string;
  setProductVersion: (v: string) => void;
  productState: ProductState;
  setProductState: (v: ProductState) => void;
  disabled: boolean;
  setShowGenericSelector: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProductInputTextField = ({
  skuOrProductConfigurationText,
  setSkuOrProductConfigurationText,
  productVersion,
  setProductVersion,
  productState,
  setProductState,
  disabled,
  setShowGenericSelector,
}: ProductInputTextFieldProps) => {
  const versionInputRef = useRef<HTMLInputElement>(null);
  const [urlHasError, setUrlHasError] = useState(false);
  const [showVersionInput, setShowVersionInput] = useState(true);

  const { data: productType } = useGetProductType({ productIdOrConfigUrl: skuOrProductConfigurationText });

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    setUrlHasError(false);

    // regex stolen from https://stackoverflow.com/a/17773849
    const urlRegex = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    );
    const isUrl = skuOrProductConfigurationText.match(urlRegex);

    if (isUrl) {
      const hostname = (() => {
        try {
          return new URL(skuOrProductConfigurationText).hostname;
        } catch {
          return '';
        }
      })();

      if (hostname.endsWith('configuration.products.cimpress.io')) {
        const newProductState = { ...productState, productConfigurationUrl: skuOrProductConfigurationText, mcpSku: '' };
        setProductState(newProductState);
      } else {
        setUrlHasError(true);
        const newProductState = { ...productState, productConfigurationUrl: '', mcpSku: '' };
        setProductState(newProductState);
      }
    } else {
      const newProductState = { ...productState, productConfigurationUrl: '', mcpSku: skuOrProductConfigurationText };
      setProductState(newProductState);
    }

    setShowGenericSelector(true);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Hide the generic selector if the user makes changes to the SKU or product configuration URL
    // so that they don't think the selection options are for the newly entered product
    setShowGenericSelector(false);
    setSkuOrProductConfigurationText(e.target.value);
  };

  const onVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductVersion(e.target.value);
  };

  const { formatMessage } = useIntl();

  // Don't show the version input when the user enters a product configuration URL
  // since it's not applicable
  useEffect(() => {
    // Show version input when there's no SKU or product configuration URL
    if (!skuOrProductConfigurationText) {
      setShowVersionInput(true);

      // Show version input when there's a SKU or product configuration URL and a product type
      // (this means it's a SKU)
    } else if (skuOrProductConfigurationText && !!productType) {
      setShowVersionInput(true);

      // Don't show version input when there's a SKU or product configuration URL, but no product type
      // (this means it's a product configuration URL)
    } else {
      setShowVersionInput(false);
    }
  }, [skuOrProductConfigurationText, productType]);

  return (
    <form onSubmit={onSubmit}>
      <TextField
        type="text"
        label={formatMessage(messages.productSkuOrProductConfiguration)}
        value={skuOrProductConfigurationText}
        onChange={onChange}
        rightAddon={
          <Button variant="default" disabled={!skuOrProductConfigurationText || disabled}>
            Search
          </Button>
        }
        required
        status={urlHasError ? 'error' : undefined}
        inputStyle={{
          borderColor: isCustomQuotesProduct({
            sku: skuOrProductConfigurationText,
          })
            ? 'red'
            : undefined,
        }}
      />
      {urlHasError ? (
        <p style={{ color: colors.danger.base }}>URL must be a valid product configuration.</p>
      ) : undefined}
      {showVersionInput && (
        <TextField
          type="search"
          label={formatMessage(messages.productVersion)}
          autoFocus={true}
          value={productVersion}
          onChange={onVersionChange}
          disabled={disabled}
          ref={versionInputRef}
        />
      )}
    </form>
  );
};
