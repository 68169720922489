import fetchWithAuth from './fetchWithAuth';

type ProductType = {
  productId: string;
  name: string;
  description: string;
  current: number;
  latest: number;
  owner: {
    accountId: string;
  };
  productType: 'Fulfiller' | 'Merchant';
};

export const getProductType = async ({ productIdOrConfigUrl }: { productIdOrConfigUrl: string }) => {
  const endpointUrl = `${process.env.REACT_APP_PRODUCT_ORCHESTRATION_URL}/v1/products/${productIdOrConfigUrl}/metadata`;
  const response = await fetchWithAuth<ProductType>({ endpointUrl });
  if (response.productType) {
    return { productType: response.productType };
  }

  return { productType: undefined };
};
