import { useQuery } from 'react-query';

import { getProductType } from '../services/productOrchestration';

export const useGetProductType = ({ productIdOrConfigUrl }: { productIdOrConfigUrl: string }) => {
  return useQuery({
    queryKey: ['product-type', productIdOrConfigUrl],
    queryFn: async () => {
      try {
        // This will throw if it's not a valid URL
        new URL(productIdOrConfigUrl);

        // Return early if the product ID is actually a product configuration URL
        return;
      } catch {
        // Only requests product type for non-URLs
        const response = await getProductType({ productIdOrConfigUrl });
        if (response.productType) {
          return response.productType;
        }
      }
    },
  });
};
