import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Router, Route, Switch } from 'react-router-dom';

import auth from './auth';
import PageNotFound from './components/404';
import CartEvaluationsPage from './components/CartEvaluationsPage';
import HomePage from './components/HomePage';
import Loading from './components/Loading';
import Shell from './components/Shell';
import translations, { DEFAULT_LANGUAGE, Language } from './i18n/locales';

auth.on('tokenExpired', () =>
  auth.login({
    nextUri: window.location.pathname,
    forceLogin: true,
    authParams: new URLSearchParams(window.location.search),
  }),
);
const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
const history = createBrowserHistory({ basename });

type AppState = {
  authenticating: boolean;
  authenticationError?: Error;
  language: Language;
};

class App extends Component {
  state: AppState = {
    authenticating: false,
    authenticationError: undefined,
    language: DEFAULT_LANGUAGE,
  };

  componentDidMount() {
    if (!auth.isLoggedIn()) {
      this.setState({ authenticating: true });
      auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
          authParams: new URLSearchParams(window.location.search),
        })
        .then(() => {
          this.setState({ authenticating: false });
        })
        .catch((err: Error) => {
          this.setState({ authenticationError: err });
        });
    }
  }

  render() {
    const { authenticating, authenticationError, language } = this.state;
    const messages = translations[language];

    return (
      <div>
        {authenticating ? (
          <Loading />
        ) : auth.isLoggedIn() ? (
          <IntlProvider locale={language} key={language} messages={messages}>
            <Router history={history}>
              <Shell>
                <Switch>
                  <Route exact path="/optionEvaluations" component={CartEvaluationsPage} />
                  <Route exact path="/(index.html)?" component={HomePage} />
                  <Route component={PageNotFound} />
                </Switch>
              </Shell>
            </Router>
          </IntlProvider>
        ) : authenticationError ? (
          <div>Unexpected error encountered. {authenticationError.message}</div>
        ) : null}
      </div>
    );
  }
}

export default App;
